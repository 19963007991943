<template lang="pug">
  .kt-wizard-v1.kt-wizard-v1--white
    setup-nav(:current-step="currentStep")
    company-form(v-if="currentStep === 0")
    data-sync-stepper(v-if="currentStep === 1")
    super-admin(v-if="currentStep === 2")
    setup-employees(v-if="currentStep === 3")
    setup-summary(v-if="currentStep === 4")
</template>

<script>
import SetupNav from './SetupNav'
import CompanyForm from '../company/Index'
import DataSyncStepper from '../dataSync/Index'
import SetupEmployees from '../employees/Index'
import SetupSummary from '../summary/Index'
import SuperAdmin from '../superAdmin/Index'

export default {
  name: 'SetupForm',

  components: {
    CompanyForm,
    SetupNav,
    DataSyncStepper,
    SetupEmployees,
    SetupSummary,
    SuperAdmin
  },

  data: () => ({
    currentStep: 0
  }),

  mounted () {
    this.setStep()
  },

  methods: {
    setStep () {
      const path = this.routePath
      if (path.includes('company')) this.currentStep = 0
      else if (path.includes('data')) this.currentStep = 1
      else if (path.includes('super-admin')) this.currentStep = 2
      else if (path.includes('employees')) this.currentStep = 3
      else if (path.includes('summary')) this.currentStep = 4
    }
  },

  computed: {
    routePath () {
      return this.$route.path
    }
  },

  watch: {
    routePath: {
      handler (path) {
        this.setStep()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
