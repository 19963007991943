<template lang="pug">
  .kt-wizard-v1__wrapper
    form-company(@on-save="nextStep" isSetupPage)
</template>

<script>
import FormCompany from './form'
import { mapActions } from 'vuex'

export default {
  name: 'Company',

  components: {
    FormCompany
  },

  async  mounted () {
    await this.loadLanguages()
  },

  methods: {
    ...mapActions('app', ['loadLanguages']),

    async nextStep () {
      this.$router.push('/setup/data')
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
