<template lang="pug">
  .kt-wizard-v1__wrapper
    .kt-form(v-if="loaded")
      .kt-wizard-v1__content(data-ktwizard-type="step-content" data-ktwizard-state="current")
        .kt-form__section.kt-form__section--first
          .kt-wizard-v1__form.mb-5(v-if="isSyncProviderForm")
            .row.justify-content-center
              .col-md-6
                .form-group
                  label {{ 'pages.setup_wizard.super_admin'  | translate}}
                  v-select(
                      name="super-admin"
                      v-model="selectedSyncUser"
                      :single="true"
                      :options="syncUsers"
                      :placeholder="`ui.dropdown.select_super_admin` | translate")
                  span.form-text.invalid {{ 'superAdminId' | errorText }}
          .kt-wizard-v1__form(v-else)
            .row.justify-content-center
              .col-md-6
                .form-group
                  label.required {{ 'pages.setup_wizard.admin_email'  | translate}}
                  input.form-control(name="company-name" :placeholder="'pages.setup_wizard.example_admin_email' | translate " v-model="employee.email")
                  span.form-text.invalid {{ 'email' | errorText }}
            .row.justify-content-center
              .col-md-6
                .form-group
                  label.required {{ 'pages.setup_wizard.password'  | translate}}
                  input.form-control(type='password' name="password" :placeholder="'pages.setup_wizard.enter_password' | translate " v-model="employee.password")
                  span.form-text.invalid {{ 'password' | errorText }}
            .row.justify-content-center
              .col-md-6
                .form-group
                  label.required {{ 'pages.setup_wizard.repeat_password'  | translate}}
                  input.form-control( type='password' name="repeatPassword" :placeholder="'pages.setup_wizard.repeat_password' | translate" v-model="employee.repeatPassword")
                  span.form-text.absolute.invalid {{ 'repeatPassword' | errorText }}
            .row
              .col-md-6
                .form-group
                  label.required {{ 'ui.labels.first_name'  | translate}}
                  input.form-control(name="firstName" :placeholder="'ui.inputs.enter_first_name' | translate" v-model="employee.firstName")
                  span.form-text.absolute.invalid {{ 'firstName' | errorText }}

              .col-md-6
                .form-group
                  label.required {{ 'ui.labels.last_name'  | translate}}
                  input.form-control(name="enter_last_name" :placeholder="'ui.inputs.enter_last_name' | translate" v-model="employee.lastName")
                  span.form-text.invalid {{ 'lastName' | errorText }}
            .row
              .col-md-6
                .form-group
                  label {{ 'ui.labels.department'  | translate}}
                  v-select(
                      name="department"
                      v-model="employee.department"
                      :single="true"
                      :enableCreteOption='true'
                      @on-create="onAddNewOptionDepartment"
                      :options="departments"
                      :placeholder="`ui.dropdown.select_department` | translate")
                  span.form-text.invalid {{ 'department' | errorText }}
              .col-md-6
                .form-group
                  label {{ 'ui.labels.position'  | translate}}
                  v-select(
                      name="position"
                      v-model="employee.position"
                      :single="true"
                      :enableCreteOption='true'
                      @on-create="onAddNewOptionPosition"
                      :options="positions"
                      :placeholder="`ui.dropdown.select_position` | translate")
                  span.form-text.invalid {{ 'position' | errorText }}
              .col-md-6
                .form-group
                  label {{ 'ui.labels.location'  | translate}}
                  v-select(
                      name="location"
                      v-model="employee.location"
                      :single="true"
                      @on-create="onAddNewOptionLocation"
                      :options="locations"
                      :enableCreteOption='true'
                      :placeholder="`ui.dropdown.select_location` | translate")
                  span.form-text.invalid {{ 'location' | errorText }}

      .kt-form__actions
        v-btn(@click="prevStep" text) {{ 'ui.buttons.go_back'  | translate}}
        v-btn(@click="nextStep" :disabled="isSyncProviderForm && !selectedSyncUser.id") {{ 'ui.buttons.next_step'  | translate}}
          i.fa.fa-arrow-right
    .super-admin-preloader-wrap(v-else)
      v-preloader
</template>

<script>
import api from '@/api'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'SuperAdmin',

  data: () => ({
    loaded: false,
    isSyncProviderForm: false,
    syncUsers: [],
    selectedSyncUser: {},
    employee: {
      email: '',
      password: '',
      repeatPassword: '',
      firstName: '',
      lastName: '',
      department: {},
      position: {},
      location: {}
    },
    positions: [],
    departments: [],
    locations: []
  }),

  async  mounted () {
    const dataSyncProvider = await api.restoreDataSyncProvider()

    if (dataSyncProvider.data && dataSyncProvider.data.type === 'CLOUD_PROVIDER') {
      this.isSyncProviderForm = true
    } else {
      this.isSyncProviderForm = false
    }

    if (this.isSyncProviderForm) {
      const { data } = await api.getSuperEmployee()
      const employees = await api.employees.list()

      if (employees.data) {
        this.syncUsers = employees.data.map(el => {
          return {
            ...el,
            name: el.firstName + ' ' + el.lastName
          }
        })

        if (data) {
          this.selectedSyncUser = this.syncUsers.find(el => el.id === data.id)
        }
      }
      this.loaded = true
    } else {
      this.getEmployee()
    }
  },

  methods: {
    ...mapActions('app', ['clearErrors']),

    async nextStep () {
      this.clearErrors()
      if (this.isSyncProviderForm) {
        const { error } = await api.postSuperEmployeeId(this.selectedSyncUser.id)
        if (!error) {
          this.$router.push('/setup/summary')
        }
        return
      }

      const body = {
        email: this.employee.email,
        password: this.employee.password,
        repeatPassword: this.employee.repeatPassword,
        firstName: this.employee.firstName,
        lastName: this.employee.lastName
      }

      body[this.employee.department.isCreated ? 'department' : 'departmentId'] = this.employee.department.isCreated
        ? { name: this.employee.department.name } : this.employee.department.id

      body[this.employee.location.isCreated ? 'location' : 'locationId'] = this.employee.location.isCreated
        ? { name: this.employee.location.name } : this.employee.location.id

      body[this.employee.position.isCreated ? 'position' : 'positionId'] = this.employee.position.isCreated
        ? { name: this.employee.position.name } : this.employee.position.id

      let updateError = true
      if (this.employee.id) {
        const { error } = await api.putSuperEmployee(this.employee.id, body)
        updateError = error
      } else {
        const { error } = await api.postSuperEmployee(body)
        updateError = error
      }

      if (!updateError) {
        this.$router.push('/setup/employees')
      }
    },

    prevStep () {
      this.$router.push('/setup/data')
    },

    onAddNewOptionDepartment (name) {
      const newRole = {
        id: name,
        name: name,
        isCreated: true
      }
      this.employee.department = newRole
      this.departments.push(newRole)
    },

    onAddNewOptionPosition (name) {
      const newRole = {
        id: name,
        name: name,
        isCreated: true
      }
      this.employee.position = newRole
      this.positions.push(newRole)
    },

    onAddNewOptionLocation (name) {
      const newRole = {
        id: name,
        name: name,
        isCreated: true
      }
      this.employee.location = newRole
      this.locations.push(newRole)
    },

    async getEmployee  () {
      const locationsData = await api.locations.list()
      const departmentsData = await api.departments.list()
      const positionsData = await api.positions.list()
      this.positions = positionsData.data || []
      this.departments = departmentsData.data || []
      this.locations = locationsData.data || []

      const { data } = await api.getSuperEmployee()
      for (let key in data) {
        if (key === 'location' || key === 'position' || key === 'department') {
          data[key] = data[key] || {}
        }
        data[key] = data[key] || ''
      }
      this.employee = { ...this.employee, ...data }
      this.loaded = true
    }

  },

  computed: {
    ...mapGetters('setupWizard', ['setupTableEmployees'])
  }
}
</script>

<style lang="scss" scoped>
  .form-group{
    margin-bottom: 1rem;
  }
  .super-admin-preloader-wrap {
    position: relative;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
